import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@canopysecurity/component-library/themes';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ApolloClientProvider } from './Context/ApolloClientProvider';
import { defaultTheme } from '@canopysecurity/component-library';
import GlobalStyle from './StyledComponents/GlobalStyle';
import { loadAsync } from 'expo-font';
import { Inter_400Regular, Inter_600SemiBold } from '@expo-google-fonts/inter';
import logo from './assets/logo.svg';
import { EnvProvider } from './Context/EnvironmentProvider';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute } from './Components/auth/OktaRoute';
import { Home } from './Pages/Home';
import { ReleasePackageWorkflow } from './Pages/ReleasePackageWorkflow/ReleasePackageWorkflow';
import { DeployPackageWorkflow } from './Pages/DeployPackageWorkflow/DeployPackageWorkflow';
import { DeploymentDetails } from './Pages/DeploymentDetails/DeploymentDetails';
import Navigation from './Components/Navigation';

const oktaAuth = new OktaAuth({
  issuer: 'https://canopysecurity.okta.com/oauth2/default',
  clientId: '0oachrx9tgXG2Ldrk697',
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
});

/**
 * Okta roles required:
 *
 * Canopy Condor
 * Canopy Condor Developer
 * Canopy Condor Release Manager
 */
function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const [isLoadingComplete, setLoadingComplete] = useState(false);
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await loadAsync({
          Inter: Inter_400Regular,
          'Inter-SemiBold': Inter_600SemiBold,
          'Px-Grotesk-Bold': require('../node_modules/@canopysecurity/component-library/assets/fonts/Px-Grotesk-Bold/Px-Grotesk-Bold.otf'),
          'Px-Grotesk': require('../node_modules/@canopysecurity/component-library/assets/fonts/Px-Grotesk-Regular/Px-Grotesk-Regular.otf'),
        });
        setLoadingComplete(true);
      } catch (e) {
        console.warn(e);
      }
    }

    loadResourcesAndDataAsync();
  }, []);
  if (!isLoadingComplete) {
    return <img src={logo} alt="Logo" />;
  } else {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <EnvProvider>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <ApolloClientProvider>
              <Routes>
                <Route path="/login/callback" element={<LoginCallback />} />
                <Route
                  element={
                    <SecureRoute>
                      <Navigation />
                    </SecureRoute>
                  }
                >
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/create-release-package"
                    element={<ReleasePackageWorkflow />}
                  />
                  <Route
                    path="/deploy-release-package"
                    element={<DeployPackageWorkflow />}
                  />
                  <Route path="/details/:id" element={<DeploymentDetails />} />
                </Route>
              </Routes>
            </ApolloClientProvider>
          </ThemeProvider>
        </EnvProvider>
      </Security>
    );
  }
}

export default function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

import React from 'react';

import { Tag } from '../../Components/Tag';

export const SelectedTagGroup = ({
  selectedTags,
  onRemove,
}: {
  selectedTags: Record<'data' | 'text', any>[] | string[];
  onRemove: (selectedGroup: Record<'data' | 'text', string> | string) => void;
}): JSX.Element => {
  return (
    <>
      {selectedTags.map(
        (selectedTag: Record<'data' | 'text', string> | string) => {
          return (
            <Tag
              key={
                typeof selectedTag === 'string' ? selectedTag : selectedTag.text
              }
              text={
                typeof selectedTag === 'string' ? selectedTag : selectedTag.text
              }
              onRemove={() => onRemove(selectedTag)}
            />
          );
        },
      )}
    </>
  );
};

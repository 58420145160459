import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Workflow } from '../../Components/workflow/Workflow';
import { SelectDeviceGroups } from './SelectDeviceGroups';
import { FinalizeDeploy } from './FinalizeDeploy';
import {
  ApplicationType,
  Countries,
  CustomerType,
  Release,
} from '../../gql/generated';

export type IndividualWorkFlow = {
  serialNumbers: string[];
};
export interface UserDeployIndividualWorkFlow {
  individual: IndividualWorkFlow;
}
export enum GroupKeys {
  countries = 'countries',
  customerTypes = 'customerTypes',
  applicationTypes = 'applicationTypes',
}
export type GroupWorkFlow = {
  [GroupKeys.countries]: Countries[];
  [GroupKeys.customerTypes]: CustomerType[];
  [GroupKeys.applicationTypes]: ApplicationType[];
};
export enum DeployMode {
  Individual,
  Group,
  DistributionGroup,
}
export interface UserDeployInfoWorkflow
  extends UserDeployGroupWorkFlow,
    UserDeployIndividualWorkFlow {
  release: Release;
  deployMode: DeployMode;
}

export interface UserDeployGroupWorkFlow {
  group: GroupWorkFlow;
}

export const DeployPackageWorkflow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [userInput, setUserInput] = useState<UserDeployInfoWorkflow>({
    deployMode: DeployMode.Group,
    release: location.state as Release,
    individual: {
      serialNumbers: [],
    },
    group: {
      [GroupKeys.countries]: [],
      [GroupKeys.customerTypes]: [],
      [GroupKeys.applicationTypes]: [],
    },
  });

  const workflowSteps: {
    title: string;
    element: JSX.Element;
  }[] = [
    {
      title: 'Select Device Groups',
      element: (
        <SelectDeviceGroups
          userInput={userInput}
          setUserInput={setUserInput}
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => navigate('/')}
        />
      ),
    },
    {
      title: 'Finalize & Deploy',
      element: (
        <FinalizeDeploy
          userInput={userInput}
          goToNextStep={() => navigate('/')}
          goToPreviousStep={() => setStep(step - 1)}
        />
      ),
    },
  ];

  return (
    <Workflow
      title={'Deploy a Release'}
      steps={workflowSteps}
      currentStep={step}
    />
  );
};

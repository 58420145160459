import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  StyledButton,
  RadioButton,
  RadioGroup,
  withTheme,
  ButtonRules,
  useTheme,
} from '@canopysecurity/component-library';
import { CheckmarkBox } from '@canopysecurity/component-library/assets';
import styled from 'styled-components';
import { ParagraphText } from '../../StyledComponents/Typography';
import PageTitleAndSub from '../../Components/PageTitleAndSub';
import cutecondor from '../../assets/cute-condor.png';
import CondorSpinner from '../../Components/CondorSpinner';
import { find, isEmpty } from 'lodash';
import { UserInputWorkflow } from './types';

import SelectedArtifactsTable from '../../Components/SelectedArtifactsTable';
import WorkflowFooter from '../../Components/workflow/WorkflowFooter';
import {
  Artifact,
  ArtifactType,
  useGetGroupedArtifactsQuery,
} from '../../gql/generated';
const WorkflowPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 10%;
`;

const SelectArtifactsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const SelectSidebar = styled.div`
  flex: 0.185;

  > * {
    margin: 10px 0;
  }
`;

const BaseTable = styled.div`
  height: fit-content;
  border-radius: 8px;
  display: grid;
  gap: 10px;
  padding: 3%;
`;

const PickerTable = withTheme(styled(BaseTable)`
  grid-template-columns: 49fr 17fr 17fr 17fr;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.secondary.darker};
`);

const TableContainer = styled.div`
  flex: 0.796;
`;
const SidebarTitle = styled.h3`
  margin-bottom: 14px;
`;

const BaseTableRow = styled.div`
  display: grid;
  border-radius: 8px;
  grid-column: span 4;
  padding: 5px;
  grid-template-columns: 49% 17% 17% 17%;
  padding: 10px 0 10px 10px;
  align-content: center;
`;

const PickerTableRow = withTheme(styled(BaseTableRow)`
  background-color: ${({ theme }) => theme.background.surface};
`);

const pickerTableHeader = [
  'ARTIFACT NAME',
  'LAST RELEASED IN',
  'LAST UPDATED',
  'DATE CREATED',
];

export const SelectArtifacts = ({
  userInput,
  setUserInput,
  goToNextStep,
  goToPreviousStep,
}: {
  userInput: UserInputWorkflow;
  setUserInput: Dispatch<SetStateAction<UserInputWorkflow>>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element => {
  const { data, loading } = useGetGroupedArtifactsQuery({
    variables: { deviceTypeName: userInput.deviceType },
    errorPolicy: 'ignore',
  });
  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);

  const getCurrentCategory = () => {
    return data?.getGroupedArtifacts?.groups[currentTypeIndex];
  };

  const { textStyles } = useTheme();

  return (
    <>
      {loading ? (
        <CondorSpinner />
      ) : data?.getGroupedArtifacts?.groups.length === 0 ? (
        <>
          <PageTitleAndSub
            title="Artifacts"
            subtitle="No Artificats returned from server."
          />
          <img
            src={cutecondor}
            alt="No artifacts to display"
            style={{ maxHeight: '70%' }}
          />
        </>
      ) : (
        <>
          <WorkflowPageContainer>
            <PageTitleAndSub
              title="Artifacts"
              subtitle="Please select the artifacts to push for the next OTA release. Only one selection per category
                allowed. You must select an artifact from the ota-app category."
            />
            <SelectArtifactsContainer data-testid={'select-artifacts'}>
              <SelectSidebar>
                <SidebarTitle>ARTIFACT CATEGORY</SidebarTitle>
                {(
                  (data?.getGroupedArtifacts?.groups ?? [])?.filter(
                    Boolean,
                  ) as ArtifactType[]
                )?.map((group, index: number) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <StyledButton
                      style={{
                        height: 60,
                        minWidth: 190,
                        width: '90%',
                        flexWrap: 'wrap',
                      }}
                      testID={`select-artifact-category-sidebar-button-${group.typeName}`}
                      key={group.typeName}
                      buttonRules={
                        currentTypeIndex === index
                          ? ButtonRules.primaryA
                          : ButtonRules.secondary
                      }
                      onPress={() => {
                        setCurrentTypeIndex(index);
                      }}
                    >
                      {group.typeName}
                    </StyledButton>
                    {!isEmpty(userInput.artifactIds[group.typeName]) ? (
                      <CheckmarkBox />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </SelectSidebar>

              <TableContainer>
                <ParagraphText
                  textStyle={textStyles.bodyMedium}
                >{`Selecting for ${
                  getCurrentCategory()?.typeName
                }`}</ParagraphText>
                <PickerTable>
                  <BaseTableRow>
                    {pickerTableHeader.map((header) => (
                      <ParagraphText
                        key={header}
                        textStyle={textStyles.bodySemiBold}
                      >
                        {header}
                      </ParagraphText>
                    ))}
                  </BaseTableRow>
                  {getCurrentCategory()?.artifacts?.length === 0 && (
                    <img src={cutecondor} alt="No artifacts to display" />
                  )}
                  {(
                    (getCurrentCategory()?.artifacts ?? []).filter(
                      Boolean,
                    ) as Artifact[]
                  ).map((artifact, index: number) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore No way to strictly declare children on styled components
                    <PickerTableRow key={artifact?.id}>
                      <RadioGroup
                        value={
                          userInput.artifactIds[
                            getCurrentCategory()?.typeName ?? ''
                          ]?.semver ?? ''
                        }
                        onChange={() => {
                          setUserInput((previousState) => ({
                            ...previousState,
                            artifactIds: {
                              ...previousState.artifactIds,
                              [getCurrentCategory()?.typeName ?? '']: artifact,
                            },
                          }));
                          if (
                            currentTypeIndex <
                            (data?.getGroupedArtifacts?.groups?.length ?? 0) - 1
                          ) {
                            setCurrentTypeIndex(currentTypeIndex + 1);
                          }
                        }}
                        labelID={'artifact name'}
                      >
                        <RadioButton
                          label={artifact.semver}
                          index={index}
                          key={artifact.semver}
                          value={artifact.semver}
                        />
                      </RadioGroup>
                      <span>{artifact.lastRelease?.semver}</span>
                      <span>
                        {new Date(artifact.lastUpdateTime).toLocaleDateString()}
                      </span>
                      <span>
                        {new Date(artifact.createTime).toLocaleDateString()}
                      </span>
                    </PickerTableRow>
                  ))}
                </PickerTable>
                <div style={{ margin: 8, paddingTop: 24 }}>
                  <ParagraphText
                    style={{ display: 'flex', padding: 8 }}
                    textStyle={textStyles.bodySemiBold}
                  >
                    Current Selection
                  </ParagraphText>
                  <ParagraphText
                    style={{ display: 'flex', padding: 8 }}
                    textStyle={textStyles.bodyMedium}
                  >
                    You must select all dependent artifacts in order for the
                    release to be successful.
                  </ParagraphText>
                </div>
                <SelectedArtifactsTable selections={userInput.artifactIds} />
              </TableContainer>
            </SelectArtifactsContainer>
          </WorkflowPageContainer>
          <WorkflowFooter
            primaryButton={{
              action: () => {
                goToNextStep();
              },
              name: 'Review Release',
              disabled: isEmpty(
                find(Object.values(userInput.artifactIds), {
                  typeName: 'ota-app',
                }),
              ),
            }}
            previousButton={{
              action: () => {
                goToPreviousStep();
              },
              visible: true,
            }}
          />
        </>
      )}
    </>
  );
};

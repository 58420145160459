/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export enum ApplicationType {
  CANOPY = 'CANOPY',
}

export type Artifact = {
  __typename?: 'Artifact';
  createTime?: Maybe<Scalars['String']['output']>;
  dependencies?: Maybe<Array<Maybe<ArtifactDependency>>>;
  downloadable?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastRelease?: Maybe<Release>;
  lastUpdateTime?: Maybe<Scalars['String']['output']>;
  semver?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
  uploadTime?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ArtifactDependency = {
  __typename?: 'ArtifactDependency';
  semver?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
};

export type ArtifactDependencyInput = {
  semver: Scalars['String']['input'];
  typeName: Scalars['String']['input'];
};

export type ArtifactInput = {
  dependencies?: InputMaybe<Array<ArtifactDependencyInput>>;
  semver: Scalars['String']['input'];
  typeName: Scalars['String']['input'];
};

export type ArtifactType = {
  __typename?: 'ArtifactType';
  compatibleDevices?: Maybe<Array<Maybe<DeviceTypeName>>>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
};

export type ArtifactTypeList = {
  __typename?: 'ArtifactTypeList';
  types?: Maybe<Array<Maybe<ArtifactType>>>;
};

export type AssociateTagWithDeviceInput = {
  serialNumber: Scalars['String']['input'];
  tagId: Scalars['ID']['input'];
};

export type AssociateTagWithDeviceResponse = {
  __typename?: 'AssociateTagWithDeviceResponse';
  deviceTag?: Maybe<DeviceTag>;
};

export enum Countries {
  CANADA = 'CANADA',
  MEXICO = 'MEXICO',
  UK = 'UK',
  US = 'US',
}

export type CreateDistributionGroupInput = {
  name: Scalars['String']['input'];
  searchCriteria: SearchCriteriaInput;
};

export type CreateTagInput = {
  tagName: Scalars['String']['input'];
};

export type CreateTagResponse = {
  __typename?: 'CreateTagResponse';
  tag?: Maybe<Tag>;
};

export enum CustomerType {
  BETA = 'BETA',
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type DeleteDistributionGroupInput = {
  distributionGroupId: Scalars['ID']['input'];
};

export type DeleteTagInput = {
  tagId: Scalars['ID']['input'];
};

export type DeleteTagResponse = {
  __typename?: 'DeleteTagResponse';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeletionResult = {
  __typename?: 'DeletionResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Deployment = {
  __typename?: 'Deployment';
  attempts?: Maybe<Array<Maybe<DeploymentAttempt>>>;
  consoleJobUrl?: Maybe<Scalars['String']['output']>;
  createTime?: Maybe<Scalars['String']['output']>;
  deploymentGroup?: Maybe<Scalars['String']['output']>;
  envName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  iotJob?: Maybe<IotJob>;
  jobId?: Maybe<Scalars['String']['output']>;
  lastDeployedBy?: Maybe<Scalars['String']['output']>;
  lastUpdateTime?: Maybe<Scalars['String']['output']>;
  release?: Maybe<Release>;
  searchCriteria?: Maybe<SearchCriteria>;
  status?: Maybe<DeploymentStatus>;
};

export type DeploymentAttempt = {
  __typename?: 'DeploymentAttempt';
  deployedBy?: Maybe<Scalars['String']['output']>;
  deployedTo?: Maybe<SearchCriteria>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type DeploymentInput = {
  envName: EnvironmentType;
  releaseId: Scalars['String']['input'];
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
};

export enum DeploymentStatus {
  ACTIVE = 'active',
  CREATED = 'created',
  INACTIVE = 'inactive',
  STRATOSPHERE = 'stratosphere',
}

export type DeviceTag = {
  __typename?: 'DeviceTag';
  deviceId?: Maybe<Scalars['ID']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  tagId?: Maybe<Scalars['ID']['output']>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  typeName?: Maybe<DeviceTypeName>;
};

export type DeviceTypeList = {
  __typename?: 'DeviceTypeList';
  types?: Maybe<Array<Maybe<DeviceType>>>;
};

export enum DeviceTypeName {
  TRUCK = 'truck',
  VAN = 'van',
}

export type DisassociateTagFromDeviceInput = {
  serialNumber: Scalars['String']['input'];
  tagId: Scalars['ID']['input'];
};

export type DisassociateTagFromDeviceResponse = {
  __typename?: 'DisassociateTagFromDeviceResponse';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DistributionGroup = {
  __typename?: 'DistributionGroup';
  createdAt?: Maybe<Scalars['String']['output']>;
  distributionGroupId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  searchCriteria?: Maybe<SearchCriteria>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum DistributionGroupStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type Environment = {
  __typename?: 'Environment';
  envName?: Maybe<Scalars['String']['output']>;
};

export type EnvironmentList = {
  __typename?: 'EnvironmentList';
  environments?: Maybe<Array<Maybe<Environment>>>;
};

export enum EnvironmentType {
  DEV = 'DEV',
  PROD = 'PROD',
  QE = 'QE',
  STAGING = 'STAGING',
}

export type GetDeviceTagsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetDeviceTagsResponse = {
  __typename?: 'GetDeviceTagsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  next?: Maybe<Scalars['String']['output']>;
  prev?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<Maybe<DeviceTag>>>;
};

export type GetDistributionGroupsInput = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type GetDistributionGroupsResponse = {
  __typename?: 'GetDistributionGroupsResponse';
  nextToken?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<DistributionGroup>>;
};

export type GetTagsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  next?: InputMaybe<Scalars['String']['input']>;
};

export type GetTagsResponse = {
  __typename?: 'GetTagsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  next?: Maybe<Scalars['String']['output']>;
  prev?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<Maybe<Tag>>>;
};

export enum Group {
  DEVELOPER = 'Developer',
  RELEASEMANAGER = 'ReleaseManager',
}

export type GroupedArtifactEntry = {
  __typename?: 'GroupedArtifactEntry';
  artifacts?: Maybe<Array<Maybe<Artifact>>>;
  typeName?: Maybe<Scalars['String']['output']>;
};

export type GroupedArtifacts = {
  __typename?: 'GroupedArtifacts';
  groups?: Maybe<Array<Maybe<GroupedArtifactEntry>>>;
};

export type IotJob = {
  __typename?: 'IOTJob';
  numberOfCanceledThings?: Maybe<Scalars['Int']['output']>;
  numberOfFailedThings?: Maybe<Scalars['Int']['output']>;
  numberOfInProgressThings?: Maybe<Scalars['Int']['output']>;
  numberOfQueuedThings?: Maybe<Scalars['Int']['output']>;
  numberOfRejectedThings?: Maybe<Scalars['Int']['output']>;
  numberOfRemovedThings?: Maybe<Scalars['Int']['output']>;
  numberOfSucceededThings?: Maybe<Scalars['Int']['output']>;
  numberOfTimedOutThings?: Maybe<Scalars['Int']['output']>;
  processingTargets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  associateTagWithDevice?: Maybe<AssociateTagWithDeviceResponse>;
  createArtifact: Artifact;
  createDeployment: Deployment;
  createDistributionGroup?: Maybe<DistributionGroup>;
  createRelease: Release;
  createTag?: Maybe<CreateTagResponse>;
  deleteDistributionGroup?: Maybe<Scalars['Boolean']['output']>;
  deleteRelease: DeletionResult;
  deleteTag?: Maybe<DeleteTagResponse>;
  disassociateTagFromDevice?: Maybe<DisassociateTagFromDeviceResponse>;
  promoteRelease: Scalars['String']['output'];
  updateDistributionGroup?: Maybe<DistributionGroup>;
  updateTag?: Maybe<UpdateTagResponse>;
};

export type MutationAssociateTagWithDeviceArgs = {
  input: AssociateTagWithDeviceInput;
};

export type MutationCreateArtifactArgs = {
  artifact: ArtifactInput;
};

export type MutationCreateDeploymentArgs = {
  deployment: DeploymentInput;
};

export type MutationCreateDistributionGroupArgs = {
  input: CreateDistributionGroupInput;
};

export type MutationCreateReleaseArgs = {
  release: ReleaseInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationDeleteDistributionGroupArgs = {
  input: DeleteDistributionGroupInput;
};

export type MutationDeleteReleaseArgs = {
  releaseId: Scalars['ID']['input'];
};

export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationDisassociateTagFromDeviceArgs = {
  input: DisassociateTagFromDeviceInput;
};

export type MutationPromoteReleaseArgs = {
  releaseId: Scalars['ID']['input'];
};

export type MutationUpdateDistributionGroupArgs = {
  input: UpdateDistributionGroupInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type PaginatedArtifactList = {
  __typename?: 'PaginatedArtifactList';
  items?: Maybe<Array<Maybe<Artifact>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type PaginatedDeploymentList = {
  __typename?: 'PaginatedDeploymentList';
  items?: Maybe<Array<Maybe<Deployment>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type PaginatedReleaseList = {
  __typename?: 'PaginatedReleaseList';
  items?: Maybe<Array<Maybe<Release>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getArtifact?: Maybe<Artifact>;
  getArtifactTypes: ArtifactTypeList;
  getArtifacts?: Maybe<PaginatedArtifactList>;
  getDeployment?: Maybe<Deployment>;
  getDeployments?: Maybe<PaginatedDeploymentList>;
  getDeviceTags?: Maybe<GetDeviceTagsResponse>;
  getDeviceTypes: DeviceTypeList;
  getDistributionGroups?: Maybe<GetDistributionGroupsResponse>;
  getEnvironments: EnvironmentList;
  getGroupedArtifacts?: Maybe<GroupedArtifacts>;
  getRelease?: Maybe<Release>;
  getReleases?: Maybe<PaginatedReleaseList>;
  getTags?: Maybe<GetTagsResponse>;
};

export type QueryGetArtifactArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetArtifactsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  typeName: Scalars['String']['input'];
};

export type QueryGetDeploymentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetDeploymentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  status: DeploymentStatus;
};

export type QueryGetDeviceTagsArgs = {
  input: GetDeviceTagsInput;
};

export type QueryGetDistributionGroupsArgs = {
  input: GetDistributionGroupsInput;
};

export type QueryGetGroupedArtifactsArgs = {
  deviceType?: InputMaybe<DeviceTypeName>;
};

export type QueryGetReleaseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetReleasesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetTagsArgs = {
  input: GetTagsInput;
};

export type Release = {
  __typename?: 'Release';
  artifacts?: Maybe<Array<Maybe<Artifact>>>;
  createTime?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypeName>;
  id?: Maybe<Scalars['ID']['output']>;
  isPromoted?: Maybe<Scalars['Boolean']['output']>;
  lastUpdateTime?: Maybe<Scalars['String']['output']>;
  releaseNotes?: Maybe<Scalars['String']['output']>;
  semver?: Maybe<Scalars['String']['output']>;
};

export type ReleaseInput = {
  artifactIds: Array<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceType: DeviceTypeName;
  releaseNotes?: InputMaybe<Scalars['String']['input']>;
  semver: Scalars['String']['input'];
};

export type SearchCriteria = {
  __typename?: 'SearchCriteria';
  applicationTypes?: Maybe<Array<Maybe<ApplicationType>>>;
  countries?: Maybe<Array<Maybe<Countries>>>;
  customerTypes?: Maybe<Array<Maybe<CustomerType>>>;
  serialNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SearchCriteriaInput = {
  applicationTypes?: InputMaybe<Array<ApplicationType>>;
  countries?: InputMaybe<Array<Countries>>;
  customerTypes?: InputMaybe<Array<CustomerType>>;
  serialNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Tag = {
  __typename?: 'Tag';
  tagId?: Maybe<Scalars['ID']['output']>;
  tagName?: Maybe<Scalars['String']['output']>;
};

export type UpdateDistributionGroupInput = {
  distributionGroupId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  status?: InputMaybe<DistributionGroupStatus>;
};

export type UpdateTagInput = {
  tagId: Scalars['ID']['input'];
  tagName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTagResponse = {
  __typename?: 'UpdateTagResponse';
  tag?: Maybe<Tag>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type Field = {
  __typename?: '__Field';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  args: Array<InputValue>;
  type: Type;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type Type = {
  __typename?: '__Type';
  kind: TypeKind;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  specifiedByURL?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Field>>;
  interfaces?: Maybe<Array<Type>>;
  possibleTypes?: Maybe<Array<Type>>;
  enumValues?: Maybe<Array<EnumValue>>;
  inputFields?: Maybe<Array<InputValue>>;
  ofType?: Maybe<Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum TypeKind {
  /** Indicates this type is a scalar. */
  SCALAR = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  INTERFACE = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL = 'NON_NULL',
}

export type CreateDeploymentMutationVariables = Exact<{
  deployment: DeploymentInput;
}>;

export type CreateDeploymentMutation = {
  __typename?: 'Mutation';
  createDeployment: {
    __typename?: 'Deployment';
    id?: string | null;
    jobId?: string | null;
  };
};

export type CreateDistributionGroupMutationVariables = Exact<{
  input: CreateDistributionGroupInput;
}>;

export type CreateDistributionGroupMutation = {
  __typename?: 'Mutation';
  createDistributionGroup?: {
    __typename?: 'DistributionGroup';
    distributionGroupId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    status?: string | null;
    searchCriteria?: {
      __typename?: 'SearchCriteria';
      countries?: Array<Countries | null> | null;
      customerTypes?: Array<CustomerType | null> | null;
      applicationTypes?: Array<ApplicationType | null> | null;
      serialNumbers?: Array<string | null> | null;
    } | null;
  } | null;
};

export type CreateReleaseMutationVariables = Exact<{
  release: ReleaseInput;
}>;

export type CreateReleaseMutation = {
  __typename?: 'Mutation';
  createRelease: { __typename?: 'Release'; id?: string | null };
};

export type PromoteReleaseMutationVariables = Exact<{
  releaseId: Scalars['ID']['input'];
}>;

export type PromoteReleaseMutation = {
  __typename?: 'Mutation';
  promoteRelease: string;
};

export type UpdateDistributionGroupMutationVariables = Exact<{
  input: UpdateDistributionGroupInput;
}>;

export type UpdateDistributionGroupMutation = {
  __typename?: 'Mutation';
  updateDistributionGroup?: {
    __typename?: 'DistributionGroup';
    distributionGroupId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    status?: string | null;
    searchCriteria?: {
      __typename?: 'SearchCriteria';
      countries?: Array<Countries | null> | null;
      customerTypes?: Array<CustomerType | null> | null;
      applicationTypes?: Array<ApplicationType | null> | null;
      serialNumbers?: Array<string | null> | null;
    } | null;
  } | null;
};

export type GetApplicationTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetApplicationTypesQuery = {
  __typename?: 'Query';
  __type?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; name: string }> | null;
  } | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  __type?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; name: string }> | null;
  } | null;
};

export type GetCustomerTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerTypesQuery = {
  __typename?: 'Query';
  __type?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; name: string }> | null;
  } | null;
};

export type GetInactiveDeploymentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInactiveDeploymentsQuery = {
  __typename?: 'Query';
  getDeployments?: {
    __typename?: 'PaginatedDeploymentList';
    items?: Array<{
      __typename?: 'Deployment';
      id?: string | null;
      status?: DeploymentStatus | null;
      envName?: string | null;
      createTime?: string | null;
      lastUpdateTime?: string | null;
      lastDeployedBy?: string | null;
      release?: {
        __typename?: 'Release';
        semver?: string | null;
        createTime?: string | null;
        lastUpdateTime?: string | null;
        deviceType?: DeviceTypeName | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetActiveDeploymentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveDeploymentsQuery = {
  __typename?: 'Query';
  getDeployments?: {
    __typename?: 'PaginatedDeploymentList';
    items?: Array<{
      __typename?: 'Deployment';
      id?: string | null;
      status?: DeploymentStatus | null;
      envName?: string | null;
      createTime?: string | null;
      lastUpdateTime?: string | null;
      lastDeployedBy?: string | null;
      release?: {
        __typename?: 'Release';
        semver?: string | null;
        createTime?: string | null;
        lastUpdateTime?: string | null;
        deviceType?: DeviceTypeName | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetDeploymentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDeploymentQuery = {
  __typename?: 'Query';
  getDeployment?: {
    __typename?: 'Deployment';
    createTime?: string | null;
    envName?: string | null;
    consoleJobUrl?: string | null;
    jobId?: string | null;
    iotJob?: {
      __typename?: 'IOTJob';
      numberOfCanceledThings?: number | null;
      numberOfSucceededThings?: number | null;
      numberOfFailedThings?: number | null;
      numberOfRejectedThings?: number | null;
      numberOfQueuedThings?: number | null;
      numberOfInProgressThings?: number | null;
      numberOfRemovedThings?: number | null;
      numberOfTimedOutThings?: number | null;
    } | null;
    release?: {
      __typename?: 'Release';
      semver?: string | null;
      id?: string | null;
      deviceType?: DeviceTypeName | null;
      artifacts?: Array<{
        __typename?: 'Artifact';
        typeName?: string | null;
        semver?: string | null;
        dependencies?: Array<{
          __typename?: 'ArtifactDependency';
          typeName?: string | null;
          semver?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
    attempts?: Array<{
      __typename?: 'DeploymentAttempt';
      startTime?: string | null;
      deployedBy?: string | null;
      deployedTo?: {
        __typename?: 'SearchCriteria';
        countries?: Array<Countries | null> | null;
        customerTypes?: Array<CustomerType | null> | null;
        applicationTypes?: Array<ApplicationType | null> | null;
        serialNumbers?: Array<string | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetDeviceTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeviceTypesQuery = {
  __typename?: 'Query';
  __type?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; name: string }> | null;
  } | null;
};

export type GetDistributionGroupsQueryVariables = Exact<{
  input: GetDistributionGroupsInput;
}>;

export type GetDistributionGroupsQuery = {
  __typename?: 'Query';
  getDistributionGroups?: {
    __typename?: 'GetDistributionGroupsResponse';
    nextToken?: string | null;
    results?: Array<{
      __typename?: 'DistributionGroup';
      distributionGroupId?: string | null;
      name?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      status?: string | null;
      searchCriteria?: {
        __typename?: 'SearchCriteria';
        countries?: Array<Countries | null> | null;
        customerTypes?: Array<CustomerType | null> | null;
        applicationTypes?: Array<ApplicationType | null> | null;
        serialNumbers?: Array<string | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type GetEnvironmentTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEnvironmentTypesQuery = {
  __typename?: 'Query';
  getEnvironments: {
    __typename?: 'EnvironmentList';
    environments?: Array<{
      __typename?: 'Environment';
      envName?: string | null;
    } | null> | null;
  };
};

export type GetGroupedArtifactsQueryVariables = Exact<{
  deviceTypeName?: InputMaybe<DeviceTypeName>;
}>;

export type GetGroupedArtifactsQuery = {
  __typename?: 'Query';
  getGroupedArtifacts?: {
    __typename?: 'GroupedArtifacts';
    groups?: Array<{
      __typename?: 'GroupedArtifactEntry';
      typeName?: string | null;
      artifacts?: Array<{
        __typename?: 'Artifact';
        id?: string | null;
        url?: string | null;
        semver?: string | null;
        typeName?: string | null;
        lastUpdateTime?: string | null;
        createTime?: string | null;
        dependencies?: Array<{
          __typename?: 'ArtifactDependency';
          typeName?: string | null;
          semver?: string | null;
        } | null> | null;
        lastRelease?: { __typename?: 'Release'; semver?: string | null } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetReleasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetReleasesQuery = {
  __typename?: 'Query';
  getReleases?: {
    __typename?: 'PaginatedReleaseList';
    items?: Array<{
      __typename?: 'Release';
      id?: string | null;
      semver?: string | null;
      deviceType?: DeviceTypeName | null;
      isPromoted?: boolean | null;
      artifacts?: Array<{
        __typename?: 'Artifact';
        semver?: string | null;
        typeName?: string | null;
        dependencies?: Array<{
          __typename?: 'ArtifactDependency';
          typeName?: string | null;
          semver?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const CreateDeploymentDocument = gql`
  mutation CreateDeployment($deployment: DeploymentInput!) {
    createDeployment(deployment: $deployment) {
      id
      jobId
    }
  }
`;
export type CreateDeploymentMutationFn = Apollo.MutationFunction<
  CreateDeploymentMutation,
  CreateDeploymentMutationVariables
>;

/**
 * __useCreateDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentMutation, { data, loading, error }] = useCreateDeploymentMutation({
 *   variables: {
 *      deployment: // value for 'deployment'
 *   },
 * });
 */
export function useCreateDeploymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeploymentMutation,
    CreateDeploymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeploymentMutation,
    CreateDeploymentMutationVariables
  >(CreateDeploymentDocument, options);
}
export type CreateDeploymentMutationHookResult = ReturnType<
  typeof useCreateDeploymentMutation
>;
export type CreateDeploymentMutationResult =
  Apollo.MutationResult<CreateDeploymentMutation>;
export type CreateDeploymentMutationOptions = Apollo.BaseMutationOptions<
  CreateDeploymentMutation,
  CreateDeploymentMutationVariables
>;
export const CreateDistributionGroupDocument = gql`
  mutation CreateDistributionGroup($input: CreateDistributionGroupInput!) {
    createDistributionGroup(input: $input) {
      distributionGroupId
      name
      searchCriteria {
        countries
        customerTypes
        applicationTypes
        serialNumbers
      }
      createdAt
      updatedAt
      status
    }
  }
`;
export type CreateDistributionGroupMutationFn = Apollo.MutationFunction<
  CreateDistributionGroupMutation,
  CreateDistributionGroupMutationVariables
>;

/**
 * __useCreateDistributionGroupMutation__
 *
 * To run a mutation, you first call `useCreateDistributionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistributionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistributionGroupMutation, { data, loading, error }] = useCreateDistributionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDistributionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDistributionGroupMutation,
    CreateDistributionGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDistributionGroupMutation,
    CreateDistributionGroupMutationVariables
  >(CreateDistributionGroupDocument, options);
}
export type CreateDistributionGroupMutationHookResult = ReturnType<
  typeof useCreateDistributionGroupMutation
>;
export type CreateDistributionGroupMutationResult =
  Apollo.MutationResult<CreateDistributionGroupMutation>;
export type CreateDistributionGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateDistributionGroupMutation,
  CreateDistributionGroupMutationVariables
>;
export const CreateReleaseDocument = gql`
  mutation CreateRelease($release: ReleaseInput!) {
    createRelease(release: $release) {
      id
    }
  }
`;
export type CreateReleaseMutationFn = Apollo.MutationFunction<
  CreateReleaseMutation,
  CreateReleaseMutationVariables
>;

/**
 * __useCreateReleaseMutation__
 *
 * To run a mutation, you first call `useCreateReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReleaseMutation, { data, loading, error }] = useCreateReleaseMutation({
 *   variables: {
 *      release: // value for 'release'
 *   },
 * });
 */
export function useCreateReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReleaseMutation,
    CreateReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReleaseMutation,
    CreateReleaseMutationVariables
  >(CreateReleaseDocument, options);
}
export type CreateReleaseMutationHookResult = ReturnType<
  typeof useCreateReleaseMutation
>;
export type CreateReleaseMutationResult =
  Apollo.MutationResult<CreateReleaseMutation>;
export type CreateReleaseMutationOptions = Apollo.BaseMutationOptions<
  CreateReleaseMutation,
  CreateReleaseMutationVariables
>;
export const PromoteReleaseDocument = gql`
  mutation PromoteRelease($releaseId: ID!) {
    promoteRelease(releaseId: $releaseId)
  }
`;
export type PromoteReleaseMutationFn = Apollo.MutationFunction<
  PromoteReleaseMutation,
  PromoteReleaseMutationVariables
>;

/**
 * __usePromoteReleaseMutation__
 *
 * To run a mutation, you first call `usePromoteReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteReleaseMutation, { data, loading, error }] = usePromoteReleaseMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function usePromoteReleaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteReleaseMutation,
    PromoteReleaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PromoteReleaseMutation,
    PromoteReleaseMutationVariables
  >(PromoteReleaseDocument, options);
}
export type PromoteReleaseMutationHookResult = ReturnType<
  typeof usePromoteReleaseMutation
>;
export type PromoteReleaseMutationResult =
  Apollo.MutationResult<PromoteReleaseMutation>;
export type PromoteReleaseMutationOptions = Apollo.BaseMutationOptions<
  PromoteReleaseMutation,
  PromoteReleaseMutationVariables
>;
export const UpdateDistributionGroupDocument = gql`
  mutation UpdateDistributionGroup($input: UpdateDistributionGroupInput!) {
    updateDistributionGroup(input: $input) {
      distributionGroupId
      name
      searchCriteria {
        countries
        customerTypes
        applicationTypes
        serialNumbers
      }
      createdAt
      updatedAt
      status
    }
  }
`;
export type UpdateDistributionGroupMutationFn = Apollo.MutationFunction<
  UpdateDistributionGroupMutation,
  UpdateDistributionGroupMutationVariables
>;

/**
 * __useUpdateDistributionGroupMutation__
 *
 * To run a mutation, you first call `useUpdateDistributionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistributionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistributionGroupMutation, { data, loading, error }] = useUpdateDistributionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDistributionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDistributionGroupMutation,
    UpdateDistributionGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDistributionGroupMutation,
    UpdateDistributionGroupMutationVariables
  >(UpdateDistributionGroupDocument, options);
}
export type UpdateDistributionGroupMutationHookResult = ReturnType<
  typeof useUpdateDistributionGroupMutation
>;
export type UpdateDistributionGroupMutationResult =
  Apollo.MutationResult<UpdateDistributionGroupMutation>;
export type UpdateDistributionGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateDistributionGroupMutation,
  UpdateDistributionGroupMutationVariables
>;
export const GetApplicationTypesDocument = gql`
  query GetApplicationTypes {
    __type(name: "ApplicationType") {
      enumValues {
        name
      }
    }
  }
`;

/**
 * __useGetApplicationTypesQuery__
 *
 * To run a query within a React component, call `useGetApplicationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicationTypesQuery,
    GetApplicationTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationTypesQuery,
    GetApplicationTypesQueryVariables
  >(GetApplicationTypesDocument, options);
}
export function useGetApplicationTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationTypesQuery,
    GetApplicationTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationTypesQuery,
    GetApplicationTypesQueryVariables
  >(GetApplicationTypesDocument, options);
}
export function useGetApplicationTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApplicationTypesQuery,
    GetApplicationTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetApplicationTypesQuery,
    GetApplicationTypesQueryVariables
  >(GetApplicationTypesDocument, options);
}
export type GetApplicationTypesQueryHookResult = ReturnType<
  typeof useGetApplicationTypesQuery
>;
export type GetApplicationTypesLazyQueryHookResult = ReturnType<
  typeof useGetApplicationTypesLazyQuery
>;
export type GetApplicationTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetApplicationTypesSuspenseQuery
>;
export type GetApplicationTypesQueryResult = Apollo.QueryResult<
  GetApplicationTypesQuery,
  GetApplicationTypesQueryVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries {
    __type(name: "Countries") {
      enumValues {
        name
      }
    }
  }
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<
  typeof useGetCountriesSuspenseQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetCustomerTypesDocument = gql`
  query GetCustomerTypes {
    __type(name: "CustomerType") {
      enumValues {
        name
      }
    }
  }
`;

/**
 * __useGetCustomerTypesQuery__
 *
 * To run a query within a React component, call `useGetCustomerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomerTypesQuery,
    GetCustomerTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerTypesQuery, GetCustomerTypesQueryVariables>(
    GetCustomerTypesDocument,
    options,
  );
}
export function useGetCustomerTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerTypesQuery,
    GetCustomerTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerTypesQuery,
    GetCustomerTypesQueryVariables
  >(GetCustomerTypesDocument, options);
}
export function useGetCustomerTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCustomerTypesQuery,
    GetCustomerTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCustomerTypesQuery,
    GetCustomerTypesQueryVariables
  >(GetCustomerTypesDocument, options);
}
export type GetCustomerTypesQueryHookResult = ReturnType<
  typeof useGetCustomerTypesQuery
>;
export type GetCustomerTypesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerTypesLazyQuery
>;
export type GetCustomerTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetCustomerTypesSuspenseQuery
>;
export type GetCustomerTypesQueryResult = Apollo.QueryResult<
  GetCustomerTypesQuery,
  GetCustomerTypesQueryVariables
>;
export const GetInactiveDeploymentsDocument = gql`
  query GetInactiveDeployments {
    getDeployments(status: inactive, limit: 250) {
      items {
        id
        status
        release {
          semver
          createTime
          lastUpdateTime
          deviceType
        }
        envName
        createTime
        lastUpdateTime
        lastDeployedBy
      }
    }
  }
`;

/**
 * __useGetInactiveDeploymentsQuery__
 *
 * To run a query within a React component, call `useGetInactiveDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInactiveDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInactiveDeploymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInactiveDeploymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInactiveDeploymentsQuery,
    GetInactiveDeploymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInactiveDeploymentsQuery,
    GetInactiveDeploymentsQueryVariables
  >(GetInactiveDeploymentsDocument, options);
}
export function useGetInactiveDeploymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInactiveDeploymentsQuery,
    GetInactiveDeploymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInactiveDeploymentsQuery,
    GetInactiveDeploymentsQueryVariables
  >(GetInactiveDeploymentsDocument, options);
}
export function useGetInactiveDeploymentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInactiveDeploymentsQuery,
    GetInactiveDeploymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInactiveDeploymentsQuery,
    GetInactiveDeploymentsQueryVariables
  >(GetInactiveDeploymentsDocument, options);
}
export type GetInactiveDeploymentsQueryHookResult = ReturnType<
  typeof useGetInactiveDeploymentsQuery
>;
export type GetInactiveDeploymentsLazyQueryHookResult = ReturnType<
  typeof useGetInactiveDeploymentsLazyQuery
>;
export type GetInactiveDeploymentsSuspenseQueryHookResult = ReturnType<
  typeof useGetInactiveDeploymentsSuspenseQuery
>;
export type GetInactiveDeploymentsQueryResult = Apollo.QueryResult<
  GetInactiveDeploymentsQuery,
  GetInactiveDeploymentsQueryVariables
>;
export const GetActiveDeploymentsDocument = gql`
  query getActiveDeployments {
    getDeployments(status: active, limit: 250) {
      items {
        id
        status
        release {
          semver
          createTime
          lastUpdateTime
          deviceType
        }
        envName
        createTime
        lastUpdateTime
        lastDeployedBy
      }
    }
  }
`;

/**
 * __useGetActiveDeploymentsQuery__
 *
 * To run a query within a React component, call `useGetActiveDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveDeploymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveDeploymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveDeploymentsQuery,
    GetActiveDeploymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveDeploymentsQuery,
    GetActiveDeploymentsQueryVariables
  >(GetActiveDeploymentsDocument, options);
}
export function useGetActiveDeploymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveDeploymentsQuery,
    GetActiveDeploymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveDeploymentsQuery,
    GetActiveDeploymentsQueryVariables
  >(GetActiveDeploymentsDocument, options);
}
export function useGetActiveDeploymentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActiveDeploymentsQuery,
    GetActiveDeploymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActiveDeploymentsQuery,
    GetActiveDeploymentsQueryVariables
  >(GetActiveDeploymentsDocument, options);
}
export type GetActiveDeploymentsQueryHookResult = ReturnType<
  typeof useGetActiveDeploymentsQuery
>;
export type GetActiveDeploymentsLazyQueryHookResult = ReturnType<
  typeof useGetActiveDeploymentsLazyQuery
>;
export type GetActiveDeploymentsSuspenseQueryHookResult = ReturnType<
  typeof useGetActiveDeploymentsSuspenseQuery
>;
export type GetActiveDeploymentsQueryResult = Apollo.QueryResult<
  GetActiveDeploymentsQuery,
  GetActiveDeploymentsQueryVariables
>;
export const GetDeploymentDocument = gql`
  query GetDeployment($id: ID!) {
    getDeployment(id: $id) {
      createTime
      envName
      consoleJobUrl
      jobId
      iotJob {
        numberOfCanceledThings
        numberOfSucceededThings
        numberOfFailedThings
        numberOfRejectedThings
        numberOfQueuedThings
        numberOfInProgressThings
        numberOfRemovedThings
        numberOfTimedOutThings
      }
      release {
        semver
        id
        deviceType
        artifacts {
          typeName
          semver
          dependencies {
            typeName
            semver
          }
        }
      }
      attempts {
        startTime
        deployedBy
        deployedTo {
          countries
          customerTypes
          applicationTypes
          serialNumbers
        }
      }
    }
  }
`;

/**
 * __useGetDeploymentQuery__
 *
 * To run a query within a React component, call `useGetDeploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeploymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeploymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDeploymentQuery,
    GetDeploymentQueryVariables
  > &
    (
      | { variables: GetDeploymentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeploymentQuery, GetDeploymentQueryVariables>(
    GetDeploymentDocument,
    options,
  );
}
export function useGetDeploymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeploymentQuery,
    GetDeploymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeploymentQuery, GetDeploymentQueryVariables>(
    GetDeploymentDocument,
    options,
  );
}
export function useGetDeploymentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeploymentQuery,
    GetDeploymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDeploymentQuery,
    GetDeploymentQueryVariables
  >(GetDeploymentDocument, options);
}
export type GetDeploymentQueryHookResult = ReturnType<
  typeof useGetDeploymentQuery
>;
export type GetDeploymentLazyQueryHookResult = ReturnType<
  typeof useGetDeploymentLazyQuery
>;
export type GetDeploymentSuspenseQueryHookResult = ReturnType<
  typeof useGetDeploymentSuspenseQuery
>;
export type GetDeploymentQueryResult = Apollo.QueryResult<
  GetDeploymentQuery,
  GetDeploymentQueryVariables
>;
export const GetDeviceTypesDocument = gql`
  query GetDeviceTypes {
    __type(name: "DeviceTypeName") {
      enumValues {
        name
      }
    }
  }
`;

/**
 * __useGetDeviceTypesQuery__
 *
 * To run a query within a React component, call `useGetDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeviceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeviceTypesQuery,
    GetDeviceTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>(
    GetDeviceTypesDocument,
    options,
  );
}
export function useGetDeviceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeviceTypesQuery,
    GetDeviceTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>(
    GetDeviceTypesDocument,
    options,
  );
}
export function useGetDeviceTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeviceTypesQuery,
    GetDeviceTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDeviceTypesQuery,
    GetDeviceTypesQueryVariables
  >(GetDeviceTypesDocument, options);
}
export type GetDeviceTypesQueryHookResult = ReturnType<
  typeof useGetDeviceTypesQuery
>;
export type GetDeviceTypesLazyQueryHookResult = ReturnType<
  typeof useGetDeviceTypesLazyQuery
>;
export type GetDeviceTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetDeviceTypesSuspenseQuery
>;
export type GetDeviceTypesQueryResult = Apollo.QueryResult<
  GetDeviceTypesQuery,
  GetDeviceTypesQueryVariables
>;
export const GetDistributionGroupsDocument = gql`
  query GetDistributionGroups($input: GetDistributionGroupsInput!) {
    getDistributionGroups(input: $input) {
      results {
        distributionGroupId
        name
        searchCriteria {
          countries
          customerTypes
          applicationTypes
          serialNumbers
        }
        createdAt
        updatedAt
        status
      }
      nextToken
    }
  }
`;

/**
 * __useGetDistributionGroupsQuery__
 *
 * To run a query within a React component, call `useGetDistributionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributionGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDistributionGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDistributionGroupsQuery,
    GetDistributionGroupsQueryVariables
  > &
    (
      | { variables: GetDistributionGroupsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDistributionGroupsQuery,
    GetDistributionGroupsQueryVariables
  >(GetDistributionGroupsDocument, options);
}
export function useGetDistributionGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistributionGroupsQuery,
    GetDistributionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDistributionGroupsQuery,
    GetDistributionGroupsQueryVariables
  >(GetDistributionGroupsDocument, options);
}
export function useGetDistributionGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDistributionGroupsQuery,
    GetDistributionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDistributionGroupsQuery,
    GetDistributionGroupsQueryVariables
  >(GetDistributionGroupsDocument, options);
}
export type GetDistributionGroupsQueryHookResult = ReturnType<
  typeof useGetDistributionGroupsQuery
>;
export type GetDistributionGroupsLazyQueryHookResult = ReturnType<
  typeof useGetDistributionGroupsLazyQuery
>;
export type GetDistributionGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetDistributionGroupsSuspenseQuery
>;
export type GetDistributionGroupsQueryResult = Apollo.QueryResult<
  GetDistributionGroupsQuery,
  GetDistributionGroupsQueryVariables
>;
export const GetEnvironmentTypesDocument = gql`
  query GetEnvironmentTypes {
    getEnvironments {
      environments {
        envName
      }
    }
  }
`;

/**
 * __useGetEnvironmentTypesQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnvironmentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEnvironmentTypesQuery,
    GetEnvironmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnvironmentTypesQuery,
    GetEnvironmentTypesQueryVariables
  >(GetEnvironmentTypesDocument, options);
}
export function useGetEnvironmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnvironmentTypesQuery,
    GetEnvironmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnvironmentTypesQuery,
    GetEnvironmentTypesQueryVariables
  >(GetEnvironmentTypesDocument, options);
}
export function useGetEnvironmentTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEnvironmentTypesQuery,
    GetEnvironmentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetEnvironmentTypesQuery,
    GetEnvironmentTypesQueryVariables
  >(GetEnvironmentTypesDocument, options);
}
export type GetEnvironmentTypesQueryHookResult = ReturnType<
  typeof useGetEnvironmentTypesQuery
>;
export type GetEnvironmentTypesLazyQueryHookResult = ReturnType<
  typeof useGetEnvironmentTypesLazyQuery
>;
export type GetEnvironmentTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetEnvironmentTypesSuspenseQuery
>;
export type GetEnvironmentTypesQueryResult = Apollo.QueryResult<
  GetEnvironmentTypesQuery,
  GetEnvironmentTypesQueryVariables
>;
export const GetGroupedArtifactsDocument = gql`
  query GetGroupedArtifacts($deviceTypeName: DeviceTypeName) {
    getGroupedArtifacts(deviceType: $deviceTypeName) {
      groups {
        typeName
        artifacts {
          id
          url
          semver
          typeName
          lastUpdateTime
          createTime
          dependencies {
            typeName
            semver
          }
          lastRelease {
            semver
          }
        }
      }
    }
  }
`;

/**
 * __useGetGroupedArtifactsQuery__
 *
 * To run a query within a React component, call `useGetGroupedArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupedArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupedArtifactsQuery({
 *   variables: {
 *      deviceTypeName: // value for 'deviceTypeName'
 *   },
 * });
 */
export function useGetGroupedArtifactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGroupedArtifactsQuery,
    GetGroupedArtifactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupedArtifactsQuery,
    GetGroupedArtifactsQueryVariables
  >(GetGroupedArtifactsDocument, options);
}
export function useGetGroupedArtifactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupedArtifactsQuery,
    GetGroupedArtifactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupedArtifactsQuery,
    GetGroupedArtifactsQueryVariables
  >(GetGroupedArtifactsDocument, options);
}
export function useGetGroupedArtifactsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGroupedArtifactsQuery,
    GetGroupedArtifactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGroupedArtifactsQuery,
    GetGroupedArtifactsQueryVariables
  >(GetGroupedArtifactsDocument, options);
}
export type GetGroupedArtifactsQueryHookResult = ReturnType<
  typeof useGetGroupedArtifactsQuery
>;
export type GetGroupedArtifactsLazyQueryHookResult = ReturnType<
  typeof useGetGroupedArtifactsLazyQuery
>;
export type GetGroupedArtifactsSuspenseQueryHookResult = ReturnType<
  typeof useGetGroupedArtifactsSuspenseQuery
>;
export type GetGroupedArtifactsQueryResult = Apollo.QueryResult<
  GetGroupedArtifactsQuery,
  GetGroupedArtifactsQueryVariables
>;
export const GetReleasesDocument = gql`
  query GetReleases {
    getReleases(limit: 250) {
      items {
        id
        semver
        artifacts {
          semver
          typeName
          dependencies {
            typeName
            semver
          }
        }
        deviceType
        isPromoted
      }
    }
  }
`;

/**
 * __useGetReleasesQuery__
 *
 * To run a query within a React component, call `useGetReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReleasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReleasesQuery,
    GetReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReleasesQuery, GetReleasesQueryVariables>(
    GetReleasesDocument,
    options,
  );
}
export function useGetReleasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReleasesQuery,
    GetReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReleasesQuery, GetReleasesQueryVariables>(
    GetReleasesDocument,
    options,
  );
}
export function useGetReleasesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetReleasesQuery,
    GetReleasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetReleasesQuery, GetReleasesQueryVariables>(
    GetReleasesDocument,
    options,
  );
}
export type GetReleasesQueryHookResult = ReturnType<typeof useGetReleasesQuery>;
export type GetReleasesLazyQueryHookResult = ReturnType<
  typeof useGetReleasesLazyQuery
>;
export type GetReleasesSuspenseQueryHookResult = ReturnType<
  typeof useGetReleasesSuspenseQuery
>;
export type GetReleasesQueryResult = Apollo.QueryResult<
  GetReleasesQuery,
  GetReleasesQueryVariables
>;

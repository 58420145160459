import React, { useCallback, useState } from 'react';

import { StyledButton } from '@canopysecurity/component-library';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import { isEmpty } from 'lodash';
import Papa from 'papaparse';
import styled from 'styled-components';

import { ParagraphText } from '../../StyledComponents/Typography';
import { SelectedTagGroup } from './SelectedTagGroup';
import { DistributionGroupDisplay } from './DistributionGroup';
import {
  DistributionGroup,
  DistributionGroupStatus,
  GetDistributionGroupsDocument,
  useGetDistributionGroupsQuery,
  useUpdateDistributionGroupMutation,
} from '../../gql/generated';

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const FormInput = styled.input`
  height: ${pixelRatio.heightPercentageToDP('3.2')}px;
  width: 100%;
`;

const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${pixelRatio.heightPercentageToDP('.2')}em 0px
    ${pixelRatio.heightPercentageToDP('0.1')}em;
  justify-content: flex-start;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  paddingtop: 20px;
`;

export function ModifyDistributionGroup() {
  const getDistributionGroupsQuery = useGetDistributionGroupsQuery({
    variables: {
      input: {
        // nextToken
      },
    },
  });

  const [modifyDistributionGroup, modifyDistributionGroupMutation] =
    useUpdateDistributionGroupMutation({
      refetchQueries: [
        {
          query: GetDistributionGroupsDocument,
          variables: {
            input: {},
          },
        },
      ],
    });

  const [distributionGroupName, setDistributionGroupName] =
    useState<string>('');

  const [currentSerialNumber, setCurrentSerialNumber] = useState<string>('');

  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);

  const handleChooseFileOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        Papa.parse(event.target.files[0], {
          header: false,
          skipEmptyLines: true,
          complete(results) {
            const serialNumberArray = results.data.flat() as string[];
            setSerialNumbers(serialNumberArray);
          },
        });
      }
    },
    [],
  );

  const [selectedDistributionGroup, setSelectedDistributionGroup] =
    useState<DistributionGroup | null>(null);
  const handleEditDistributionGroup = useCallback(
    (distributionGroup: DistributionGroup) => {
      setSelectedDistributionGroup(distributionGroup);
      setDistributionGroupName(distributionGroup.name ?? '');
      setSerialNumbers(distributionGroup.searchCriteria?.serialNumbers ?? []);
    },
    [],
  );

  const handleModifyDistributionGroup = useCallback(() => {
    modifyDistributionGroup({
      variables: {
        input: {
          distributionGroupId:
            selectedDistributionGroup?.distributionGroupId ?? '',
          name: distributionGroupName,
          searchCriteria: {
            serialNumbers,
          },
        },
      },
      refetchQueries: [
        {
          query: GetDistributionGroupsDocument,
          variables: {
            input: {},
          },
        },
      ],
    })
      .then(() => {
        setDistributionGroupName('');
        setSerialNumbers([]);
        setSelectedDistributionGroup(null);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    modifyDistributionGroup,
    selectedDistributionGroup?.distributionGroupId,
    distributionGroupName,
    serialNumbers,
  ]);

  const handleDeleteDistributionGroup = useCallback(
    (distributionGroup: DistributionGroup) => {
      modifyDistributionGroup({
        variables: {
          input: {
            distributionGroupId: distributionGroup.distributionGroupId ?? '',
            status: DistributionGroupStatus.INACTIVE,
          },
        },
        refetchQueries: [
          {
            query: GetDistributionGroupsDocument,
            variables: {
              input: {},
            },
          },
        ],
      })
        .then(() => {
          setDistributionGroupName('');
          setSerialNumbers([]);
          setSelectedDistributionGroup(null);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [modifyDistributionGroup],
  );

  return (
    <div>
      <FormLabel>Modify an Existing Distribution Group</FormLabel>
      <ParagraphText>
        To modify a distribution group, edit the name for the group and add or
        remove serial numbers of the devices to include.
      </ParagraphText>

      {!selectedDistributionGroup ? (
        getDistributionGroupsQuery?.loading ? (
          <ParagraphText>Loading...</ParagraphText>
        ) : getDistributionGroupsQuery?.data?.getDistributionGroups?.results
            ?.length ? (
          <Grid>
            {getDistributionGroupsQuery.data?.getDistributionGroups?.results?.map(
              (distributionGroup, index) => (
                <DistributionGroupDisplay
                  key={distributionGroup.distributionGroupId ?? String(index)}
                  distributionGroup={distributionGroup}
                  onEdit={handleEditDistributionGroup}
                  onDelete={handleDeleteDistributionGroup}
                  loading={modifyDistributionGroupMutation.loading}
                />
              ),
            )}
          </Grid>
        ) : (
          <ParagraphText>
            There are no distribution groups to modify.
          </ParagraphText>
        )
      ) : (
        <>
          <FormInput
            placeholder="Enter a name for the Distribution Group"
            maxLength={120}
            value={distributionGroupName}
            autoCapitalize="none"
            onChange={(event) => setDistributionGroupName(event.target.value)}
          />
          <FormLabelContainer>
            <div style={{ flex: 0.6 }}>
              <FormInput
                placeholder="Enter a Serial Number"
                maxLength={120}
                value={currentSerialNumber}
                autoCapitalize="none"
                onChange={(event): void =>
                  setCurrentSerialNumber(event.target.value)
                }
              />
            </div>
            <div style={{ flex: 0.1, marginLeft: '1em' }}>
              <StyledButton
                disabled={currentSerialNumber.length < 1}
                onPress={() => {
                  setSerialNumbers((previousSerialNumbers) => [
                    ...previousSerialNumbers,
                    currentSerialNumber,
                  ]);
                  setCurrentSerialNumber('');
                }}
              >
                Add
              </StyledButton>
            </div>
            <div style={{ flex: 0.2, marginLeft: '1em' }}>
              <input
                type={'file'}
                multiple={false}
                accept={'.csv'}
                onChange={handleChooseFileOnChange}
              />
            </div>
            <div>
              <StyledButton onPress={() => setSelectedDistributionGroup(null)}>
                Cancel
              </StyledButton>
            </div>
          </FormLabelContainer>
          <FormLabelContainer>
            <FormLabel>Selected Devices</FormLabel>
          </FormLabelContainer>
          {isEmpty(serialNumbers) ? (
            <ParagraphText>No devices added.</ParagraphText>
          ) : (
            <div>
              <SelectedTagGroup
                selectedTags={serialNumbers}
                onRemove={(sn) =>
                  setSerialNumbers((prevSerialNumbers) =>
                    prevSerialNumbers.filter((value) => value !== sn),
                  )
                }
              />
              <StyledButton
                disabled={
                  distributionGroupName.length < 1 ||
                  serialNumbers.length < 1 ||
                  modifyDistributionGroupMutation.loading
                }
                onPress={handleModifyDistributionGroup}
              >
                Save
              </StyledButton>
            </div>
          )}
        </>
      )}
    </div>
  );
}

import React, { Dispatch, SetStateAction, useState } from 'react';
import WorkflowFooter from '../../Components/workflow/WorkflowFooter';
import {
  DeployMode,
  GroupKeys,
  UserDeployInfoWorkflow,
} from './DeployPackageWorkflow';
import {
  RadioButton,
  RadioGroup,
  StyledButton,
} from '@canopysecurity/component-library';
import { HeadingText, ParagraphText } from '../../StyledComponents/Typography';
import styled from 'styled-components';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import { isEmpty } from 'lodash';
import Papa from 'papaparse';
import { DistributionGroups } from './DistributionGroups';
import { SelectedTagGroup } from './SelectedTagGroup';
import {
  ApplicationType,
  Countries,
  CustomerType,
  useGetApplicationTypesQuery,
  useGetCountriesQuery,
  useGetCustomerTypesQuery,
} from '../../gql/generated';

const FormInput = styled.input`
  height: ${pixelRatio.heightPercentageToDP('3.2')}px;
  width: 100%;
`;

const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${pixelRatio.heightPercentageToDP('.2')}em 0px
    ${pixelRatio.heightPercentageToDP('0.1')}em;
  justify-content: flex-start;
  align-items: center;
`;

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const CheckboxContainer = styled.span`
  margin-right: 24px;
`;

const Checkbox = ({
  displayText,
  checked,
  onChange,
}: {
  displayText: string;
  checked: boolean;
  onChange: (value: string) => void;
}): JSX.Element => {
  return (
    <CheckboxContainer>
      <input
        key={displayText}
        type="checkbox"
        data-testid={`checkbox-${displayText}`}
        checked={checked}
        onChange={() => {
          onChange(displayText);
        }}
      />
      <label htmlFor={`checkbox-${displayText}`}>{displayText}</label>
    </CheckboxContainer>
  );
};

export const SelectDeviceGroups = ({
  userInput,
  setUserInput,
  goToPreviousStep,
  goToNextStep,
}: {
  userInput: UserDeployInfoWorkflow;
  setUserInput: Dispatch<SetStateAction<UserDeployInfoWorkflow>>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element => {
  const { data: countries } = useGetCountriesQuery();
  const { data: applicationTypes } = useGetApplicationTypesQuery();
  const { data: customerTypes } = useGetCustomerTypesQuery();
  const [serialNumber, setSerialNumber] = useState('');
  const submitValuesRef: React.RefObject<any> = React.createRef();
  const isPrimaryButtonDisabled = () =>
    (userInput.deployMode === DeployMode.Group &&
      Object.values(userInput.group).flat().length === 0) ||
    (userInput.deployMode === DeployMode.Individual &&
      isEmpty(userInput.individual.serialNumbers)) ||
    (userInput.deployMode === DeployMode.DistributionGroup &&
      isEmpty(userInput.individual.serialNumbers));
  const checkboxSelectionChange =
    (groupName: GroupKeys) => (groupType: string) => {
      setUserInput((prevState) => ({
        ...prevState,
        group: {
          ...prevState.group,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore No way to properly index this
          [groupName]: prevState.group[groupName].includes(groupType)
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore No way to properly index this
              prevState.group[groupName].filter((value) => value !== groupType)
            : [...prevState.group[groupName], groupType],
        },
      }));
    };

  const onSubmit = (): void => {
    if (submitValuesRef.current) submitValuesRef.current();
  };
  const generateTagData = () => {
    return Object.entries(userInput.group).reduce(
      (
        acc: [] | Record<'data' | 'text', string | Record<string, any>>[],
        [key, values],
      ) => {
        const flattenedValue = values.map((value) => ({
          data: { [key]: value },
          text: value,
        }));
        return [...acc, ...flattenedValue];
      },
      [],
    );
  };

  console.log({ userInput });
  const handleChooseFileOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files?.length) {
      Papa.parse(event.target.files[0], {
        header: false,
        skipEmptyLines: true,
        complete(results) {
          const serialNumberArray = results.data.flat() as string[];
          setUserInput((prevState) => ({
            ...prevState,
            individual: {
              serialNumbers: serialNumberArray,
            },
          }));
        },
      });
    }
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <>
        <FormLabelContainer>
          <FormLabel>Deploy To</FormLabel>
        </FormLabelContainer>
        <RadioGroup
          labelID={'deploy-mode-radio-group'}
          value={userInput.deployMode}
          onChange={(value: DeployMode) => {
            setUserInput((previousState) => ({
              ...previousState,
              deployMode: value,
            }));
          }}
        >
          <RadioButton
            value={DeployMode.Group}
            index={0}
            label={'Device Groups'}
          />
          <RadioButton
            value={DeployMode.Individual}
            index={1}
            label={'Specific Devices'}
          />
          <RadioButton
            value={DeployMode.DistributionGroup}
            index={1}
            label="Distribution Groups"
          />
        </RadioGroup>
        {userInput.deployMode === DeployMode.Group && (
          <div style={{ paddingTop: '2%' }}>
            <FormLabel>Device Groups</FormLabel>
            <ParagraphText>
              If you wish to deploy to one or more device groups, please check
              the boxes next to your selected groups.
            </ParagraphText>
            <div>
              <div>
                <HeadingText>Geography</HeadingText>
                <div>
                  {countries &&
                    countries.__type?.enumValues?.map(
                      (country: { name: string }) => {
                        return (
                          <Checkbox
                            key={country.name}
                            displayText={country.name}
                            checked={userInput.group[
                              GroupKeys.countries
                            ].includes(country?.name as Countries)}
                            onChange={checkboxSelectionChange(
                              GroupKeys.countries,
                            )}
                          />
                        );
                      },
                    )}
                </div>
              </div>
              <div>
                <HeadingText>Application Type</HeadingText>
                <div>
                  {applicationTypes &&
                    applicationTypes?.__type?.enumValues?.map(
                      (application: { name: string }) => {
                        return (
                          <Checkbox
                            key={application.name}
                            displayText={application.name}
                            checked={userInput.group[
                              GroupKeys.applicationTypes
                            ].includes(application.name as ApplicationType)}
                            onChange={checkboxSelectionChange(
                              GroupKeys.applicationTypes,
                            )}
                          />
                        );
                      },
                    )}
                </div>
              </div>
              <div>
                <HeadingText>Customer Type</HeadingText>
                <div>
                  {customerTypes &&
                    customerTypes?.__type?.enumValues?.map(
                      (customerType: { name: string }) => {
                        return (
                          <Checkbox
                            key={customerType.name}
                            displayText={customerType.name}
                            checked={userInput.group[
                              GroupKeys.customerTypes
                            ].includes(customerType.name as CustomerType)}
                            onChange={checkboxSelectionChange(
                              GroupKeys.customerTypes,
                            )}
                          />
                        );
                      },
                    )}
                </div>
              </div>
            </div>
            <FormLabelContainer>
              <FormLabel>Selected Device Groups</FormLabel>
            </FormLabelContainer>
            {Object.values(userInput.group).flat().length === 0 ? (
              <ParagraphText>No groups selected.</ParagraphText>
            ) : (
              <SelectedTagGroup
                selectedTags={generateTagData()}
                onRemove={(selectedTag) => {
                  if (typeof selectedTag !== 'string') {
                    checkboxSelectionChange(
                      Object.keys(selectedTag.data)[0] as GroupKeys,
                    )(
                      Object.values(selectedTag.data)[0] as
                        | Countries
                        | ApplicationType
                        | CustomerType,
                    );
                  }
                }}
              />
            )}
          </div>
        )}
        {userInput.deployMode === DeployMode.Individual && (
          <div style={{ paddingTop: '2%' }}>
            <FormLabel>Specific Devices</FormLabel>
            <ParagraphText>
              If you wish to deploy to one or more specific devices, please
              enter the serial number of the device below.
            </ParagraphText>
            <FormLabelContainer>
              <div style={{ flex: 0.6 }}>
                <FormInput
                  placeholder="Enter a Serial Number"
                  maxLength={120}
                  value={serialNumber}
                  autoCapitalize="none"
                  onChange={(event): void =>
                    setSerialNumber(event.target.value)
                  }
                />
              </div>
              <div style={{ flex: 0.1, marginLeft: '1em' }}>
                <StyledButton
                  disabled={serialNumber.length < 1}
                  onPress={() => {
                    setUserInput((previousState) => ({
                      ...previousState,
                      individual: {
                        serialNumbers: [
                          ...previousState.individual.serialNumbers,
                          serialNumber,
                        ],
                      },
                    }));
                    setSerialNumber('');
                  }}
                >
                  Add
                </StyledButton>
              </div>
              <div style={{ flex: 0.2, marginLeft: '1em' }}>
                <input
                  type={'file'}
                  multiple={false}
                  accept={'.csv'}
                  onChange={handleChooseFileOnChange}
                />
              </div>
            </FormLabelContainer>
            <FormLabelContainer>
              <FormLabel>Selected Devices</FormLabel>
            </FormLabelContainer>
            {isEmpty(userInput.individual.serialNumbers) ? (
              <ParagraphText>No device added.</ParagraphText>
            ) : (
              <SelectedTagGroup
                selectedTags={userInput.individual.serialNumbers}
                onRemove={(sn) =>
                  setUserInput((prevState) => ({
                    ...prevState,
                    individual: {
                      serialNumbers: prevState.individual.serialNumbers.filter(
                        (value) => value !== sn,
                      ),
                    },
                  }))
                }
              />
            )}
          </div>
        )}
        {userInput.deployMode === DeployMode.DistributionGroup && (
          <div style={{ paddingTop: '2%' }}>
            <DistributionGroups setUserInput={setUserInput} />
          </div>
        )}

        <WorkflowFooter
          primaryButton={{
            action: () => {
              onSubmit();
              goToNextStep();
            },
            name: 'Continue',
            disabled: isPrimaryButtonDisabled(),
          }}
          previousButton={{
            action: () => {
              goToPreviousStep();
            },
            visible: true,
          }}
        />
      </>
    </div>
  );
};

import React from 'react';
import PageTitleAndSub from '../../Components/PageTitleAndSub';
import { PageContainer } from '../../StyledComponents/PageContainer';
import SelectedArtifactsTable from '../../Components/SelectedArtifactsTable';
import WorkflowFooter from '../../Components/workflow/WorkflowFooter';
import { ParagraphText } from '../../StyledComponents/Typography';
import { UserInputWorkflow } from './types';
import checkmark from '../../assets/checkmark.svg';
import { DisplayDataRow } from '../../Components/DisplayDataRow';
import { scrollToTop } from '../../utils/Window';
import styled from 'styled-components';
import { CloseIcon, useTheme } from '@canopysecurity/component-library';
import { DeviceTypeName, useCreateReleaseMutation } from '../../gql/generated';

const StatusBar = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 0;
  > * {
    margin-right: 16px;
  }
`;

export const ReviewRelease = ({
  userInput,
  goToNextStep,
  goToPreviousStep,
}: {
  userInput: UserInputWorkflow;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element => {
  const [createRelease, { data, loading, error }] = useCreateReleaseMutation({
    errorPolicy: 'all',
  });
  if (data || error) {
    scrollToTop();
  }

  const { textStyles } = useTheme();

  return (
    <PageContainer>
      {!data && !error && (
        <PageTitleAndSub
          title="Review Release"
          subtitle="Review the following data for release. The release data cannot be edited after this point.
          If you wish to package the release and return home, click “Package & Close”."
        />
      )}
      {data && (
        <StatusBar>
          <img src={checkmark} alt="checkmark" />
          <ParagraphText textStyle={textStyles.bodySemiBold}>
            🎉 ✨ 🎊 Your new release package is created! You can now view it in
            In Progress OTA Updates. 🎉 ✨ 🎊
          </ParagraphText>
        </StatusBar>
      )}
      {error && (
        <StatusBar>
          <CloseIcon testID="close-icon" />
          <ParagraphText textStyle={textStyles.bodySemiBold}>
            There was a problem packaging your release data. Go back and edit
            the package or try again later. {error.message}
          </ParagraphText>
        </StatusBar>
      )}
      <DisplayDataRow label={'Release Title'} data={userInput.semver} />
      <DisplayDataRow label={'Device Type'} data={userInput.deviceType} />
      <DisplayDataRow
        label={'Selected Artifacts'}
        data={<SelectedArtifactsTable selections={userInput.artifactIds} />}
      />
      <WorkflowFooter
        primaryButton={{
          action: () => {
            if (data) {
              goToNextStep();
            } else {
              createRelease({
                variables: {
                  release: {
                    semver: userInput.semver ?? '',
                    artifactIds: Object.values(userInput.artifactIds).map(
                      (artifact) => artifact.id,
                    ),
                    deviceType: userInput.deviceType as DeviceTypeName,
                  },
                },
              });
            }
          },
          name: data ? 'Close' : 'Package & Continue',
          disabled: false,
          loading,
        }}
        previousButton={{
          action: () => {
            goToPreviousStep();
          },
          visible: true,
        }}
      />
    </PageContainer>
  );
};

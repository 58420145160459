import React, { useState } from 'react';
import { ButtonRules, StyledButton } from '@canopysecurity/component-library';
import { ParagraphText } from '../../StyledComponents/Typography';
import styled from 'styled-components';
import Modal from 'react-modal';
import { DistributionGroup } from '../../gql/generated';

const DistributionGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  border-bottom: 1px solid black;
  padding: 0.5em;
  border-radius: 8;
  border-width: 1;
  border-style: solid;
  border-color: rgba(0; 0; 0; 0.5);
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
`;

const DistributionGroupName = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

const DistributionGroupSerialNumbersHeader = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const CTAButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 16px;
`;

const CTAButtonAlign = styled.div`
  flex: 1;
  display: flex;
`;
interface DistributionGroupDisplayProps {
  distributionGroup: DistributionGroup;
  onEdit?: (distributionGroup: DistributionGroup) => void;
  onDelete?: (distributionGroup: DistributionGroup) => void;
  onDeploy?: (distributionGroup: DistributionGroup) => void;
  isSelected?: boolean;
  loading?: boolean;
}
export function DistributionGroupDisplay({
  distributionGroup,
  onEdit,
  onDelete,
  onDeploy,
  isSelected = false,
  loading = false,
}: DistributionGroupDisplayProps) {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  return (
    <DistributionGroupContainer>
      <DistributionGroupName>
        {distributionGroup.name ?? 'No Name for this Distribution Group'}
      </DistributionGroupName>
      <DistributionGroupSerialNumbersHeader>
        Serial Numbers
      </DistributionGroupSerialNumbersHeader>
      <div>
        {distributionGroup?.searchCriteria?.serialNumbers?.length ? (
          <ul>
            {(distributionGroup.searchCriteria?.serialNumbers ?? [])?.map(
              (serialNumber) => (
                <li key={serialNumber}>{serialNumber}</li>
              ),
            )}
          </ul>
        ) : (
          <ParagraphText>
            This distribution group does not have any serial numbers.
          </ParagraphText>
        )}
      </div>
      <CTAButtonContainer>
        {onEdit && onDelete ? (
          <>
            <CTAButtonAlign>
              <StyledButton
                onPress={() => {
                  onEdit(distributionGroup);
                }}
              >
                Edit
              </StyledButton>
            </CTAButtonAlign>
            <CTAButtonAlign>
              <StyledButton
                onPress={() => {
                  setDeleteModalVisible(true);
                }}
                buttonRules={ButtonRules.secondary}
              >
                Delete
              </StyledButton>
            </CTAButtonAlign>
          </>
        ) : onDeploy ? (
          <CTAButtonAlign>
            <StyledButton
              disabled={
                !distributionGroup?.searchCriteria?.serialNumbers?.length ||
                distributionGroup?.searchCriteria?.serialNumbers?.length < 1
              }
              onPress={() => {
                onDeploy(distributionGroup);
              }}
              buttonRules={
                isSelected ? ButtonRules.primaryA : ButtonRules.secondary
              }
            >
              {isSelected
                ? 'Deploy to this Distribution Group'
                : 'Select for Deployment'}
            </StyledButton>
          </CTAButtonAlign>
        ) : (
          <ParagraphText>Unknown Error with Distribution Group</ParagraphText>
        )}
      </CTAButtonContainer>
      {onDelete && (
        <Modal
          isOpen={deleteModalVisible}
          onRequestClose={() => {
            setDeleteModalVisible(false);
          }}
          style={{
            content: {
              padding: 16,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 8,
              minWidth: '40vw',
              position: undefined,
            },
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999,
            },
          }}
        >
          <ParagraphText>
            Are you sure you want to delete this Distribution Group?
          </ParagraphText>
          <ParagraphText>
            <b>{distributionGroup?.name}</b>
          </ParagraphText>
          <CTAButtonContainer>
            <CTAButtonAlign>
              <StyledButton
                disabled={loading}
                onPress={() => {
                  setDeleteModalVisible(false);
                }}
                buttonRules={ButtonRules.secondary}
              >
                Cancel
              </StyledButton>
            </CTAButtonAlign>
            <CTAButtonAlign>
              <StyledButton
                disabled={loading}
                onPress={() => {
                  onDelete(distributionGroup);
                  setDeleteModalVisible(false);
                }}
                buttonRules={ButtonRules.primaryA}
              >
                {loading ? 'Deleting...' : 'Delete'}
              </StyledButton>
            </CTAButtonAlign>
          </CTAButtonContainer>
        </Modal>
      )}
    </DistributionGroupContainer>
  );
}

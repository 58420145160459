import React, { useCallback, useState } from 'react';

import { StyledButton } from '@canopysecurity/component-library';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import { isEmpty } from 'lodash';
import Papa from 'papaparse';
import styled from 'styled-components';

import { ParagraphText } from '../../StyledComponents/Typography';
import { SelectedTagGroup } from './SelectedTagGroup';
import {
  GetDistributionGroupsDocument,
  useCreateDistributionGroupMutation,
} from '../../gql/generated';

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const FormInput = styled.input`
  height: ${pixelRatio.heightPercentageToDP('3.2')}px;
  width: 100%;
`;

const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${pixelRatio.heightPercentageToDP('.2')}em 0px
    ${pixelRatio.heightPercentageToDP('0.1')}em;
  justify-content: flex-start;
  align-items: center;
`;

interface CreateDistributionGroupProps {
  onCreate?: () => void;
}
export function CreateDistributionGroup({
  onCreate,
}: CreateDistributionGroupProps) {
  const [createDistributionGroup, createDistributionGroupMutation] =
    useCreateDistributionGroupMutation();

  const [distributionGroupName, setDistributionGroupName] =
    useState<string>('');

  const [currentSerialNumber, setCurrentSerialNumber] = useState<string>('');

  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);

  const handleChooseFileOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        Papa.parse(event.target.files[0], {
          header: false,
          skipEmptyLines: true,
          complete(results) {
            const serialNumberArray = results.data.flat() as string[];
            setSerialNumbers(serialNumberArray);
          },
        });
      }
    },
    [],
  );

  const handleCreateDistributionGroup = useCallback(() => {
    createDistributionGroup({
      variables: {
        input: {
          name: distributionGroupName,
          searchCriteria: {
            serialNumbers,
          },
        },
      },
      refetchQueries: [
        {
          query: GetDistributionGroupsDocument,
          variables: {
            input: {},
          },
        },
      ],
    })
      .then(() => {
        setDistributionGroupName('');
        setSerialNumbers([]);
        if (onCreate) {
          onCreate();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [createDistributionGroup, distributionGroupName, serialNumbers, onCreate]);

  return (
    <div>
      <FormLabel>Create a Distribution Group</FormLabel>
      <ParagraphText>
        To create a distribution group, enter a name for the group and provide
        the serial numbers of the devices to include.
      </ParagraphText>

      <FormInput
        placeholder="Enter a name for the Distribution Group"
        maxLength={120}
        value={distributionGroupName}
        autoCapitalize="none"
        onChange={(event) => setDistributionGroupName(event.target.value)}
      />

      <FormLabelContainer>
        <div style={{ flex: 0.6 }}>
          <FormInput
            placeholder="Enter a Serial Number"
            maxLength={120}
            value={currentSerialNumber}
            autoCapitalize="none"
            onChange={(event): void =>
              setCurrentSerialNumber(event.target.value)
            }
          />
        </div>
        <div style={{ flex: 0.1, marginLeft: '1em' }}>
          <StyledButton
            disabled={currentSerialNumber.length < 1}
            onPress={() => {
              setSerialNumbers((previousSerialNumbers) => [
                ...previousSerialNumbers,
                currentSerialNumber,
              ]);
              setCurrentSerialNumber('');
            }}
          >
            Add
          </StyledButton>
        </div>
        <div style={{ flex: 0.2, marginLeft: '1em' }}>
          <input
            type={'file'}
            multiple={false}
            accept={'.csv'}
            onChange={handleChooseFileOnChange}
          />
        </div>
      </FormLabelContainer>
      <FormLabelContainer>
        <FormLabel>Selected Devices</FormLabel>
      </FormLabelContainer>
      {isEmpty(serialNumbers) ? (
        <ParagraphText>No devices added.</ParagraphText>
      ) : (
        <div>
          <SelectedTagGroup
            selectedTags={serialNumbers}
            onRemove={(sn) =>
              setSerialNumbers((prevSerialNumbers) =>
                prevSerialNumbers.filter((value) => value !== sn),
              )
            }
          />
          <StyledButton
            disabled={
              distributionGroupName.length < 1 ||
              serialNumbers.length < 1 ||
              createDistributionGroupMutation.loading
            }
            onPress={handleCreateDistributionGroup}
          >
            Save
          </StyledButton>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import {
  DropDown,
  useTheme,
  withTheme,
} from '@canopysecurity/component-library';
import styled from 'styled-components';
import {
  ParagraphText,
  SectionTitleText,
} from '../../StyledComponents/Typography';
import cutecondor from '../../assets/cute-condor.png';
import CondorSpinner from '../../Components/CondorSpinner';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import { HomeBaseTableRow } from '../../StyledComponents/Table';
import {
  DeviceTypeName,
  GroupedArtifactEntry,
  useGetGroupedArtifactsQuery,
} from '../../gql/generated';

const DropdownContainer = styled.div``;

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

const ArtifactTableContainer = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 1% 2% 1% 2%;
  height: 80%;
  overflow-y: hidden;
`;

const TableRows = styled.div`
  grid-template-columns: 25fr 25fr 50fr;
  display: grid;
  width: 100%;
  height: 80%;
  overflow-y: auto;
`;

const ArtifactTableRow = styled(HomeBaseTableRow)`
  grid-template-columns: 25fr 25fr 20fr 15fr 15fr;
`;

const ArtifactTableHeaderRow = withTheme(styled(ArtifactTableRow)`
  display: grid;
  background-color: ${({ theme }) => theme.background.main};
`);

const ArtifactTableHeader = [
  'ARTIFACT NAME',
  'ARTIFACT TYPE',
  'LAST RELEASED IN',
  'LAST UPDATED',
  'DATE CREATED',
];

interface ArtifactTableProps {
  deviceList: string[];
  activeDeployments: any;
}

export function ArtifactTable({
  deviceList = [],
  activeDeployments = [],
}: ArtifactTableProps) {
  const theme = useTheme();

  const [selectedArtifactDeviceType, setSelectedArtifactDeviceType] = useState(
    deviceList?.[0] ?? 'truck',
  );
  const { data: artifacts, loading: artifactsLoading } =
    useGetGroupedArtifactsQuery({
      variables: {
        deviceTypeName: selectedArtifactDeviceType as DeviceTypeName,
      },
      errorPolicy: 'ignore',
    });

  const groupNames = useMemo(() => {
    return (
      (artifacts?.getGroupedArtifacts?.groups ?? []).filter(
        Boolean,
      ) as GroupedArtifactEntry[]
    )?.map((group) => group.typeName);
  }, [artifacts?.getGroupedArtifacts?.groups]);

  const [selectedArtifactGroupType, setSelectedArtifactGroupType] = useState(
    groupNames?.[0] ?? 'ota-app',
  );
  useEffect(() => {
    if (groupNames?.length) {
      setSelectedArtifactGroupType(groupNames[0]);
    }
  }, [groupNames]);

  const selectedGroupIndex = useMemo(() => {
    return artifacts?.getGroupedArtifacts?.groups?.findIndex(
      (group: any) => group.typeName === selectedArtifactGroupType,
    );
  }, [artifacts?.getGroupedArtifacts?.groups, selectedArtifactGroupType]);

  if (artifactsLoading) {
    return <CondorSpinner />;
  }

  return (
    <TableContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '16px',
        }}
      >
        <SectionTitleText>Artifacts</SectionTitleText>
        <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          <DropdownContainer>
            <DropDown
              items={deviceList}
              onValueChange={(value: string) =>
                setSelectedArtifactDeviceType(value)
              }
              placeholderText={'Filter by Device'}
              dropdownListContainerStyle={{
                width: pixelRatio.widthPercentageToDP('10'),
                zIndex: 99,
              }}
              selectedValue={selectedArtifactDeviceType}
            />
          </DropdownContainer>
        </div>
        <div style={{ marginLeft: '8px' }}>
          <DropdownContainer>
            <DropDown
              items={groupNames}
              onValueChange={(value: string) =>
                setSelectedArtifactGroupType(value)
              }
              placeholderText={'Select Artifact Group'}
              dropdownListContainerStyle={{
                width: pixelRatio.widthPercentageToDP('10'),
                zIndex: 99,
              }}
              selectedValue={selectedArtifactGroupType}
            />
          </DropdownContainer>
        </div>
      </div>
      <ArtifactTableContainer>
        <ArtifactTableHeaderRow>
          {ArtifactTableHeader.map((header) => (
            <SectionTitleText key={header}>{header}</SectionTitleText>
          ))}
        </ArtifactTableHeaderRow>
        {!activeDeployments ||
        activeDeployments.getDeployments?.items.length === 0 ? (
          <img
            src={cutecondor}
            alt="No deployed releases to display"
            style={{ maxHeight: '70%' }}
          />
        ) : (
          <TableRows>
            {artifacts?.getGroupedArtifacts?.groups?.[
              selectedGroupIndex ?? 0
            ]?.artifacts?.map((artifact: any, index: number) => {
              return (
                <ArtifactTableRow
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0
                        ? theme.secondary.darker
                        : theme.background.surface,
                  }}
                >
                  <ParagraphText style={{ alignSelf: 'center' }}>
                    {artifact.semver}
                  </ParagraphText>
                  <ParagraphText style={{ alignSelf: 'center' }}>
                    {artifact.typeName}
                  </ParagraphText>
                  <ParagraphText style={{ alignSelf: 'center' }}>
                    {artifact.lastRelease?.semver ?? '-'}
                  </ParagraphText>
                  <ParagraphText style={{ alignSelf: 'center' }}>
                    {new Date(artifact.lastUpdateTime).toLocaleDateString()}
                  </ParagraphText>
                  <ParagraphText style={{ alignSelf: 'center' }}>
                    {new Date(artifact.createTime).toLocaleDateString()}
                  </ParagraphText>
                </ArtifactTableRow>
              );
            })}
          </TableRows>
        )}
      </ArtifactTableContainer>
    </TableContainer>
  );
}

import React from 'react';
import {
  ButtonRules,
  StyledButton,
  useTheme,
} from '@canopysecurity/component-library';
import { ParagraphText } from '../StyledComponents/Typography';
import {
  DeployBaseTableRow,
  HomeBaseTableRow,
} from '../StyledComponents/Table';
import { useNavigate } from 'react-router-dom';
import { Deployment, Release } from '../gql/generated';
import { usePromotionModal } from '../Context/PromotionModal/usePromotionModal';

export default function DeployPackageTableRow({
  deployedPackages,
  tableType = 'deploy',
}: {
  deployedPackages: Deployment[] | Release[];
  tableType?: 'release' | 'deploy';
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { canPromotePackages, showPromotionModal } = usePromotionModal();

  return (
    <>
      {tableType === 'deploy'
        ? (deployedPackages as Deployment[]).map(
            (deployedPackage: Deployment, index: number) => (
              <DeployBaseTableRow
                key={deployedPackage.id}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? theme.secondary.darker
                      : theme.background.surface,
                }}
              >
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {deployedPackage.release.semver}
                </ParagraphText>
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {new Date(deployedPackage.createTime).toLocaleDateString(
                    'en-UK',
                  )}
                </ParagraphText>
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {deployedPackage.envName}
                </ParagraphText>
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {deployedPackage.release.deviceType}
                </ParagraphText>
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {deployedPackage.lastDeployedBy ?? 'unknown'}
                </ParagraphText>
                <div
                  style={{
                    marginRight: '0.6em',
                    justifySelf: 'stretch',
                    marginLeft: 'auto',
                    maxWidth: 200,
                    width: '100%',
                  }}
                >
                  <StyledButton
                    onPress={() => {
                      navigate(`/details/${deployedPackage.id}`);
                    }}
                    buttonRules={ButtonRules.primaryA}
                  >
                    Details
                  </StyledButton>
                </div>
              </DeployBaseTableRow>
            ),
          )
        : (deployedPackages as Release[]).map(
            (release: Release, index: number) => (
              <HomeBaseTableRow
                key={release.id}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? theme.secondary.darker
                      : theme.background.surface,
                }}
              >
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {release.semver}
                </ParagraphText>
                <ParagraphText style={{ alignSelf: 'center' }}>
                  {release.deviceType}
                </ParagraphText>
                <div
                  style={{
                    marginRight: '0.6em',
                    justifySelf: 'stretch',
                    marginLeft: 'auto',
                    maxWidth: 200,
                    width: '100%',
                  }}
                >
                  {canPromotePackages() && (
                    <StyledButton
                      onPress={() => showPromotionModal(release)}
                      disabled={!!release.isPromoted}
                      buttonRules={ButtonRules.primaryB}
                      testID={`promote-button-${release.id}`}
                    >
                      Promote
                    </StyledButton>
                  )}
                </div>
                <div
                  style={{
                    marginRight: '0.6em',
                    justifySelf: 'stretch',
                    marginLeft: 'auto',
                    maxWidth: 200,
                    width: '100%',
                  }}
                >
                  <StyledButton
                    onPress={() => {
                      navigate('/deploy-release-package', {
                        state: release,
                      });
                    }}
                    disabled={false}
                    buttonRules={ButtonRules.primaryA}
                  >
                    Deploy
                  </StyledButton>
                </div>
              </HomeBaseTableRow>
            ),
          )}
    </>
  );
}

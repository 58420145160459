import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { ParagraphText } from '../../StyledComponents/Typography';
import { DistributionGroupDisplay } from './DistributionGroup';
import { UserDeployInfoWorkflow } from './DeployPackageWorkflow';
import {
  DistributionGroup,
  useGetDistributionGroupsQuery,
} from '../../gql/generated';

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  paddingtop: 20px;
`;

interface DeployDistributionGroupProps {
  setUserInput: React.Dispatch<React.SetStateAction<UserDeployInfoWorkflow>>;
}
export function DeployDistributionGroup({
  setUserInput,
}: DeployDistributionGroupProps) {
  const getDistributionGroupsQuery = useGetDistributionGroupsQuery({
    variables: {
      input: {
        // nextToken
      },
    },
  });

  const [selectedDistributionGroup, setSelectedDistributionGroup] =
    useState<DistributionGroup | null>(null);

  const handleDeployDistributionGroup = useCallback(
    (distributionGroup: DistributionGroup) => {
      setSelectedDistributionGroup(distributionGroup);
      setUserInput((previousState) => ({
        ...previousState,
        individual: {
          ...previousState.individual,
          serialNumbers: distributionGroup.searchCriteria?.serialNumbers ?? [],
        },
      }));
    },
    [setUserInput],
  );

  return (
    <div>
      <FormLabel>Deploy to an Existing Distribution Group</FormLabel>
      <ParagraphText>Select a distribution group to deploy to.</ParagraphText>
      {getDistributionGroupsQuery?.loading ? (
        <ParagraphText>Loading...</ParagraphText>
      ) : getDistributionGroupsQuery?.data?.getDistributionGroups?.results
          ?.length ? (
        <Grid>
          {getDistributionGroupsQuery.data?.getDistributionGroups?.results?.map(
            (distributionGroup, index) => (
              <DistributionGroupDisplay
                key={distributionGroup.distributionGroupId ?? String(index)}
                distributionGroup={distributionGroup}
                isSelected={
                  selectedDistributionGroup?.distributionGroupId ===
                  distributionGroup?.distributionGroupId
                }
                onDeploy={handleDeployDistributionGroup}
              />
            ),
          )}
        </Grid>
      ) : (
        <ParagraphText>
          There are no distribution groups to modify.
        </ParagraphText>
      )}
    </div>
  );
}

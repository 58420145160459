import React, { useState } from 'react';

import {
  ItemDirections,
  RadioButton,
  RadioGroup,
} from '@canopysecurity/component-library';
import styled from 'styled-components';

import { ParagraphText } from '../../StyledComponents/Typography';
import { CreateDistributionGroup } from './CreateDistributionGroup';
import { ModifyDistributionGroup } from './ModifyDistributionGroup';
import { UserDeployInfoWorkflow } from './DeployPackageWorkflow';
import { DeployDistributionGroup } from './DeployDistributionGroup';

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const Container = styled.div`
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  display: flex;
  padding: 20px;
`;

const Divider = styled.div`
  width: 1px;
  min-height: 100px;
  background-color: black;
  margin-left: 20px;
  margin-right: 20px;
`;
enum DistributionGroupMode {
  Create,
  Deploy,
  Modify,
}

interface DistributionGroupsProps {
  setUserInput: React.Dispatch<React.SetStateAction<UserDeployInfoWorkflow>>;
}

export function DistributionGroups({ setUserInput }: DistributionGroupsProps) {
  const [mode, setMode] = useState<DistributionGroupMode>(
    DistributionGroupMode.Create,
  );

  return (
    <div>
      <FormLabel>Distribution Groups</FormLabel>
      <ParagraphText>
        Distribution Groups are used to manage deployments in bulk to specific
        device serial numbers.
      </ParagraphText>
      <Container>
        <div>
          <RadioGroup
            labelID="distribution-group-radio-group"
            value={mode}
            onChange={(value: DistributionGroupMode) => {
              setMode(value);
            }}
            itemDirection={ItemDirections.vertical}
          >
            <RadioButton
              value={DistributionGroupMode.Create}
              index={0}
              label="Create a Distribution Group"
            />
            <RadioButton
              value={DistributionGroupMode.Deploy}
              index={1}
              label="Deploy to Existing Distribution Group"
            />
            <RadioButton
              value={DistributionGroupMode.Modify}
              index={1}
              label="Modify Existing Distribution Group"
            />
          </RadioGroup>
        </div>
        <Divider />
        <div
          style={{
            flex: 1,
          }}
        >
          {mode === DistributionGroupMode.Create && (
            <CreateDistributionGroup
              onCreate={() => setMode(DistributionGroupMode.Deploy)}
            />
          )}
          {mode === DistributionGroupMode.Deploy && (
            <DeployDistributionGroup setUserInput={setUserInput} />
          )}
          {mode === DistributionGroupMode.Modify && <ModifyDistributionGroup />}
        </div>
      </Container>
    </div>
  );
}
